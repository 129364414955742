.cookiesPrivacyText {
  color: #222 !important;
  font-size: 14px;
  margin-top: 5px;
}

.cookiesPrivacyText a {
  color: #ff385c !important;
  text-decoration: underline;
  font-weight: 600;
}

.logo {
  position: relative;
  top: -60px;
}

.pageTitle {
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .logo {
    top: -20px;
  }
}
